<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('refreshList')">
        <div class="row">
          <div class="col-sm-3 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Cognome"
              aria-label=""
              :value="cognomeHPAnagrafiche"
              @input="setCognomeHPAnagrafiche"
            />
          </div>
          <div class="col-sm-3 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              aria-label=""
              :value="nomeHPAnagrafiche"
              @input="setNomeHPAnagrafiche"
            />
          </div>
          <div class="col-sm-3 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Codice fiscale"
              aria-label=""
              :value="codFiscHPAnagrafiche"
              @input="setCodFiscHPAnagrafiche"
            />
          </div>
          <div class="col-sm-3 filter">
            <input
              type="text"
              class="form-control"
              placeholder="E-mail"
              aria-label=""
              :value="emailHPAnagrafiche"
              @input="setEmailHPAnagrafiche"
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <span
            type="button"
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('refreshList')"
            >Cerca <i class="bi bi-search fs-6 text-gray-600"> </i
          ></span>
          <span
            type="button"
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "filter-hp-pretesseramento",
  emits: ["resetFilters", "refreshList"],
  components: {},
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const nomeHPAnagrafiche = computed(() => store.getters.nomeHPAnagrafiche);
    const setNomeHPAnagrafiche = (event) => {
      const string = event.target.value;
      store.commit("setNomeHPAnagrafiche", string);
    };

    const cognomeHPAnagrafiche = computed(
      () => store.getters.cognomeHPAnagrafiche
    );
    const setCognomeHPAnagrafiche = (event) => {
      const string = event.target.value;
      store.commit("setCognomeHPAnagrafiche", string);
    };

    const codFiscHPAnagrafiche = computed(
      () => store.getters.codFiscHPAnagrafiche
    );
    const setCodFiscHPAnagrafiche = (event) => {
      const string = event.target.value;
      store.commit("setCodFiscHPAnagrafiche", string);
    };

    const emailHPAnagrafiche = computed(() => store.getters.emailHPAnagrafiche);
    const setEmailHPAnagrafiche = (event) => {
      const string = event.target.value;
      store.commit("setEmailHPAnagrafiche", string);
    };

    return {
      nomeHPAnagrafiche,
      setNomeHPAnagrafiche,
      cognomeHPAnagrafiche,
      setCognomeHPAnagrafiche,
      codFiscHPAnagrafiche,
      setCodFiscHPAnagrafiche,
      emailHPAnagrafiche,
      setEmailHPAnagrafiche,
    };
  },
});
</script>

<style></style>
